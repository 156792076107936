.modal-open {
    button.hamburger {
        opacity: 0;
        pointer-events: none;
    }
    .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
        background-color: #fff;
    }
    .winefinderwrap {
        filter: blur(7px);
    }
}


.modal-dialog {
    max-width: calc(100vw - 30px);
    margin-left: 15px;
    @media (min-width: 1024px){
        max-width: 75vw;
        margin-left: auto;
    }
    @media (min-width: 1200px){
        max-width: 66.66666vw;
    }
}


