// ctype SCSS
.ce-winefinder {
    font-family: "Yanone Kaffeesatz";
    color: $black;

    .filter {
        font-size: em(30);
        font-weight: bold;
        position: sticky;
        top: 30px;
        .inner{
            @media all and (min-width: 540px) and (max-width: 991px){
                display: flex;
                flex-wrap: wrap;
                > ul{
                    flex:1;
                }
            }
        }
        ul {
            list-style: none;
            margin: 30px 15px;
            padding: 0;
            background: none;
            li {
                list-style: none;
                padding: 5px 10px;
                &.head {
                    font-weight: 400;
                    padding: 10px;
                    cursor: default;
                }
                &:not(.head){
                    &:hover, &:focus, &:active {
                        font-weight: 300;
                    }
                }
            }
            @media all and (max-width: 539px) and (min-width: 400px){
                display: flex;
                flex-wrap: wrap;
                li{
                    flex: 1 1 100%;
                    max-width: 100%;
                    &:not(.head){
                        flex: 1 1 50%;
                        max-width: 50%;
                    }
                }
            }
            &.bg-green{
                li{
                    background: transparentize($green, .5);
                    &.head{
                        background: $green;

                    }
                }
            }
            &.bg-pink{
                li{
                    background: transparentize($pink, .5);
                    &.head{
                        background: $pink;

                    }
                }
            }
            &.bg-violett{
                li{
                    background: transparentize($violett, .5);
                    &.head{
                        background: $violett;

                    }
                }
            }
        }
    }

    .blockquote {
        font-size: 2rem;
    }

    .blockquote-footer {
        font-size: 75%;
        color: $black;
    }

    small {
        font-size: 60%;
        font-weight: 100;
    }

    .modal{
        max-width: 100vw;
        .close{
            position: absolute;
            top: 22px;
            width: 45px;
            height: 45px;
            text-align: center;
            right: 22px;
            background-color: #fff;
            font-size: 3.8rem;
            text-shadow: none;
            border-radius: 3rem;
            opacity: 1;
            transform: scale(.75);
            span{
                width: 45px;
                height: 45px;
                overflow: hidden;
                line-height: 35px;
                display: table;
            }
        }
    }

    @media (max-width: 575.98px) {
        .modal-dialog {
            margin: 0;
            padding: 15px;
            padding-right: 0;
        }
    }

    .modal-content {
        border-radius: 0;
        margin-left: auto;
        margin-right: auto;
        width: fit-content !important;
        padding: 50px;
        border: 30px solid $pink;
        background: transparent;
        max-width: 100%;
        .row{
            align-items: center;
        }
        @media (max-width: 575.98px) {
            padding: 20px;
            .row {
                max-width: 100vw;
                margin: 0;

            }
        }

        p {
            margin-bottom: 0;
            font-weight: 300;
        }

        h1 {
            font-family: "Yanone Kaffeesatz";
            font-size: 4.44444em;
            font-weight: 500;
            line-height: 1;
            position: relative;
            margin-bottom: 0.4em;
            padding-right: 1.4em;
            position: relative;

            .year{
                background: #000;
                color: #fff;
                line-height: 1;
                font-size: .4em;
                font-weight: 600;
                padding: 3px 7px 5px;
                position: absolute;
                top: 0;
                right: 0;
            }
            + .blockquote{
                > p:first-child{
                    margin: 10px 0;
                    padding: 10px 0;
                    border: 5px solid;
                    border-left: 0;
                    border-right: 0;
                }
            }
            @media all and (max-width: 1023px){
                font-size: 3.2em;
            }
            @media all and (max-width: 767px){
                padding-right: 0;
                .year{
                    top: -1.25em;
                }
            }
            @media all and (max-width: 575px){
                margin-top: 2em;
                hyphens: auto;
            }
        }
    }
    .blockquote-footer::before{
        content: "";
        margin: 20px 0;
        display: block;
    }
    .modal-content::after {
        content: "";
        background-image: url(../Images/page/menu-bg.jpg);
        background-position: center;
        background-attachment: fixed;
        opacity: 1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
    }
}
