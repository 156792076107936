@font-face {
    font-family: 'ostrich_sansmedium';
    src: url('../Fonts/ostrich-regular-webfont.woff2') format('woff2'),
    url('../Fonts/ostrich-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'ostrich_sansblack';
    src: url('../Fonts/ostrichsans-black-webfont.woff2') format('woff2'),
    url('../Fonts/ostrichsans-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'ostrich_sanslight';
    src: url('../Fonts/ostrichsans-light-webfont.woff2') format('woff2'),
    url('../Fonts/ostrichsans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

p {
    font-weight: 300;
}

.center-text {
    h2 {
        font-size: 18px;
    }

    p {
        font-size: 18px;
    }
}


$browser-context: 18;

@function em($pixels, $context: $browser-context) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return $pixels / $context * 1em;
}

$black: #2c2c2c;
$green: #62b32e;
$pink: #e6027e;
$violett: #623d91;
$white: white;

@mixin bg-colored($color) {
    background-color: $color;
    color: white;
    font-family: "Yanone Kaffeesatz";
    font-weight: 200;

}

.col-xs-half {
    @media all and (max-width: 575px) and (min-width: 400px) {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.gmelin-logo {
    margin-top: 10px;
    width: 150px;
    //-webkit-filter: drop-shadow(0 12px 5px rgba(0,0,0,0.5));
    //filter: drop-shadow(0 12px 5px rgba(0,0,0,0.5));

    .st0 {
        fill: $pink;
        //animation: col2 20s linear infinite;
        .green-bg & {
            fill: $green;
        }

        .pink-bg & {
            fill: $pink;
        }

        .violett-bg & {
            fill: $violett;
        }
    }

    .st1 {
        fill: $black;
        //animation: col 70s linear infinite;
    }
}

.logobesen {
    width: 300px;
    margin-left: 50px;

    .a {
        fill: saddlebrown;
    }
}

@keyframes col {
    0%, 45% {
        fill: $black
    }
    50%, 95% {
        fill: white
    }
    100% {
        fill: $black
    }
}

@keyframes col2 {
    0%, 32% {
        fill: $green
    }
    33%, 65% {
        fill: $pink
    }
    66%, 98% {
        fill: $violett
    }
    99%, 100% {
        fill: $green
    }
}

:root {
    --bgColor: #000;

}

.bg-green {
    @include bg-colored($green);

}

.bg-pink {
    @include bg-colored($pink);

}

.bg-violett {
    @include bg-colored($violett);

}

@import "../utilities/button";
@import "../utilities/shadow";

.green {
    color: $green;
}

.pink {
    color: $pink;
}

.violett {
    color: $violett;
}

.black {
    color: $black;
}

.white {
    color: white;
}

.green-bg {
    background-color: #62b32e;
    color: white;
    --bgColor: #{$green};
}

.pink-bg {
    background-color: #e6027e;
    color: white;
    --bgColor: #{$pink};
}

.violett-bg {
    background-color: #623d91;
    color: white;
    --bgColor: #{$violett};
}

.black-bg {
    background-color: $black;
    color: white;
    --bgColor: #{$black};
}

.white-bg {
    background-color: white;
    color: $black;
    --bgColor: #{$white};
}

@import url('https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz:200,300,400');

body {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-size: em(14);
    font-weight: 200;
    transition: background-color 2s;
    background: $black;
    @media all and (max-width: 1599) {
        background: none !important;
        .pagewrap {
            box-shadow: none !important;
        }
    }
}

.loader {
    background-color: #2c2c2c;
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
}

h1, h2, h3 {
    font-weight: 400;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}

footer.ftr {
    text-align: center;
    font-size: 20px;

    a {
        color: $pink;
        font-weight: 300;

        &:hover {
            color: white;
            text-decoration: none;
            font-weight: 300;
        }
    }

    .footerlinks {
        padding: 7rem 1.5rem !important;
        color: $black;
        font-weight: 300;

        .imprint {
            padding: 6rem 0 0;
            font-weight: 500;
            font-size: 16px;

            a {
                color: $black;
                font-weight: 500;
            }
        }
    }
}

main {
    position: static;
}

.modal-backdrop {
    background-color: #000;
}

.modal-backdrop.show {
    opacity: .65;
}

.modal-dialog {
    min-height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    @media(max-width: 768px) {
        min-height: calc(100vh - 20px);
    }
}

.modal-content {
    overflow: hidden;
}

.pagewrap {
    width: 100%;
    max-width: 1600px;
    min-width: 320px;
    margin-left: auto;
    margin-right: auto;
    position: static;
    background: url(../Images/page/menu-bg.jpg) center / fixed;
    box-shadow: 0 0 30px -10px rgba(0, 0, 0, .5);
    transition: all 1s;
}


.logo {
    a {
        display: block;
    }

    right: 50%;
    transform: translate(50%);
    position: absolute;
    top: 0;
    margin-top: em(15);
    @include media-breakpoint-down(xs) {
        right: 0;
        padding-right: 10px;
        transform: translate(0);
        img {
            width: 50px;
            height: auto;
        }
    }
}

header {
    .logo {
        margin: 0;
        padding: 50px 0 70px;
        transform: none;
        position: static;

        .gmelin-logo {
            display: block;
            margin: 0 auto;
        }
    }
}

.mainarea {
    //@include box_shadow(1);
}

section {
    position: relative;
    z-index: 10;
    color: $black;
}

section.stage {
    z-index: 2;
    @include media-breakpoint-down(xs) {
        display: none;
    }
    max-height: 50vh;
    background-size: cover;
    background-attachment: fixed;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: translateY(40%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 120%);
    }

    &.wine {
        flex-direction: row-reverse;
        background-image: url(../Images/page/winefinder-header.jpg);
        background-position: center;
        padding-top: 0;

        .currenwine {
            margin-right: 10%;
            height: 90%;
        }

        &:after {
            position: absolute;
            z-index: 10;
            width: 100%;
            padding: 50px;
            top: 50%;
            transform: translateY(-50%);
            font-size: calc(15px + 7vh);
            font-weight: 600;
            text-shadow: 0 -0.1em 0.2em rgba(0, 0, 0, 0.7);
            text-align: center;
            color: #fff;
        }
    }

    display: flex;
    flex-wrap: wrap;


    .bottle {
        position: relative;
        z-index: 2;
        transform: translateY(100px);
        text-align: center;
        transition: .5s cubic-bezier(.25, .8, .25, 1);
        display: flex;

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: url(../Images/page/bottle-shadow.png) 68% 10% no-repeat;
            transition: all 0.5s cubic-bezier(.25, .8, .25, 1);
            @media all and (max-width: 767px) {
                content: none;
            }

        }

        a.bottlelink {
            width: 0;
            height: 0;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 5000;
        }

        &:hover, &:focus {
            transition: all 0.5s cubic-bezier(.25, .8, .25, 1);
            transform: translateY(30px);

            &:before {
                transform: translateY(5%);
            }

            .cat {
                transition: all 0.5s cubic-bezier(.25, .8, .25, 1);
                transform: rotate(-90deg) translateX(65%) translateY(-100px);
            }

            a.bottlelink {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        img {
            z-index: 5;
            position: relative;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .cat {
            z-index: 0;
            width: 200px;
            position: absolute;
            top: 0;
            line-height: 1;
            left: 50%;
            transition: .5s cubic-bezier(.25, .8, .25, 1);
            transform: rotate(-90deg) translateX(-50%) translateY(-100px);
            font-size: 2.22222em;
            text-align: left;
            font-family: ostrich_sansblack, sans-serif;
        }
    }
}

section.text-image {
    background-color: white;
    display: block;

    .square-6 {
        width: 50%;

        img {
            width: 100%;
            max-width: none;
        }

        @media (max-width: 575.98px) {
            float: none;
            width: 100%;
        }
    }

    .square-6.text {
        position: relative;

        h2 {
            font-size: 1em;
            font-weight: 600;
        }

        .center-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: em(10);
        }
    }
}

.winefinderwrap {
    //overflow-x: hidden;
    transition: filter .15s 0s;

    .col-lg-9 {

    }

    .container {
        max-width: 100%;
    }

    > .row {
        margin-left: -15px;
        margin-right: -15px;
    }

    position: relative;
    //background-image: url(../Images/page/menu-bg.jpg);

    .winefilter {
        z-index: 1000;
        position: absolute;
        right: 0;
        top: 0;
    }

    .wine {
        position: absolute;
        cursor: pointer;

        .title {
            font-family: "Yanone Kaffeesatz";
            font-weight: 400;
            font-size: 1.8rem;
            position: absolute;
            position: absolute;
            bottom: 0;
            right: 0;
            white-space: nowrap;
            color: #2c2c2c;

            span {
                display: block;
                transform: rotate(90deg) translateY(4em);
                transform-origin: right bottom;
                @media all and (max-width: 1170px) {
                    transform: rotate(90deg) translateY(3.5em);
                }
                @media all and (max-width: 767px) {
                    transform: rotate(90deg) translateY(3em);
                }
            }

            .sold {
                font-size: 1rem;
                padding-left: .5rem;
                color: $pink;
            }
        }
    }

    .filter {
        text-align: center;

        li {
            cursor: pointer;
            padding: 0;
            margin: 0;
        }
    }

    section.winefinder {
        min-height: 500px;
    }
}

.winefinder {
    img.img-fluid {
        margin-left: auto;
        margin-right: auto;
        display: block;
        transform: translateX(-30px);
    }
}


// GMAP & Footer //

#map {
    width: 100%;
    height: 50vh;
}

// MENU //
button.hamburger {
    z-index: 2001;
    position: fixed;
    top: 0;
    transform: translateX(-50%);
    text-align: right;
    width: 100%;
    max-width: 1400px;
    left: 50%;
    padding: 30px 30px 0 0;

    &:focus {
        border: none;
        outline: none;
    }

    .hamburgerBG {
        //background: #fff;
        padding: 10px;
        padding-top: 14px;
        display: inline-block;
        transform: scale(0.9);
        transform-origin: top right;
    }

    .hamburgerBox {
        display: block;
    }
}

.overlay-menu {
    z-index: 2000;
    //@include box_shadow(3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: url(../Images/page/menu-bg.jpg) center;
    background-size: cover;
    transform: translateX(calc(-100% - 25px));
    transition: all 0.5s cubic-bezier(.25, .8, .25, 1);

    &.is-active {
        transform: translateX(0);
        transition: all 0.5s cubic-bezier(.25, .8, .25, 1);
    }

    .bigmenu {
        font-family: 'ostrich_sansmedium', sans-serif;
        font-weight: normal;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);

        .btn {
            font-size: em(80);
            font-weight: bold;
            margin-bottom: em(5);
            border: none;
            min-width: 50vw;
            @include media-breakpoint-down(xs) {
                min-width: 90vw;
            }
        }

        list-style: none;
        padding: 0;
        margin: 0;
        text-align: center;

        li {
            margin: 0;

            a {
                color: $black;
                text-transform: uppercase;
            }
        }

    }
}

// MENU //

.btn.focus, .btn:focus {
    box-shadow: none;
}

